/** @jsx jsx */
import { Text, Flex, jsx } from "theme-ui";

let WorkLink = ({ companyName, companyPageUrl, ...props }) => (
  <Text as="h3" variant="workLink" {...props}>
    <Flex
      as="a"
      target="_blank"
      rel="noopener noreferrer nofollow"
      href={companyPageUrl}
    >
      {companyName}
    </Flex>
  </Text>
);

export default WorkLink;
