import React from "react";
import { Box, Text } from "theme-ui";
import cv from "./cv";

export default () => (
  <Box variant="cv.container" mb={20}>
    <Text as="h2" mt={2} color="grey">
      {cv.title}
    </Text>
    <Text as="p" my={1}>
      Based in {cv.location}
    </Text>
  </Box>
);
