/** @jsx jsx */
import { Box, Text, Flex, jsx } from "theme-ui";
import cv from "./cv";

import { SectionTitle, SkillItem } from ".";
import WorkLink from "components/WorkLink";

export default () => (
  <Box>
    <SectionTitle>Work Experience</SectionTitle>
    {cv.experience.map((_) => (
      <Box key={_.companyName}>
        <WorkLink {..._} />
        <Flex
          sx={{
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: ["flex-start", "flex-start", "space-between"],
          }}
        >
          {_.stack.map(SkillItem)}
        </Flex>
        <Flex
          sx={{
            mb: 3,
            mt: 2,
            flexWrap: "wrap",
          }}
          css={`
            font-weight: bold;
            p {
              margin: 0;
            }
          `}
        >
          <Text as="p" sx={{ fontSize: 4 }}>
            {_.position}
          </Text>
          <Box as="span" mx={2} aria-hidden sx={{ fontSize: 4, opacity: 0.3 }}>
            |
          </Box>
          <Text as="p" sx={{ fontSize: 4, color: "grey" }}>
            {_.term}
          </Text>
        </Flex>
        <ul>
          {_.responsibilities.map((responsibility) => (
            <Text key={responsibility} as="li" mb={2}>
              {responsibility}
            </Text>
          ))}
        </ul>
      </Box>
    ))}
  </Box>
);
