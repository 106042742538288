export default {
  fullName: "James Barzegar",
  title: "JavaScript Developer",
  location: "Toronto Ontario, Canada",
  contactInfo: {
    email: {
      title: "james.barzegar@gmail.com",
      href: "mailto:james.barzegar@gmail.com",
    },
    github: {
      title: "jbarzegar",
      href: "https://github.com/jbarzegar",
    },
    linkedIn: {
      title: "in/james-barzegar-686a74106",
      href: "https://www.linkedin.com/in/james-barzegar-686a74106/",
    },
  },
  skillList: [
    "TypeScript",
    "React/Redux/Hooks/etc",
    "Jest",
    "NodeJS",
    "Storybook driven development",
  ],
  experience: [
    {
      companyName: "1Password",
      companyPageUrl: "https://1password.com",
      position: "Senior Developer | Internal Tools",
      stack: ["Typescript", "Prisma", "React"],
      term: "March 2022 - Present",
      responsibilities: [
        "Was one of three project leads that would plan, lead & implement features in projects.",
        "Created, architected and led a project to build an internal tool platform for company wide use. Enabling all employees of 1Password to write their own internal tools without direct involvement from the Internal Tools team.",
        "Supported and mentored; interns, juniors and intermediate developers respectively. Enabling to do their best work in a supportive environment.",
        "Communicated status updates about OKRs, new features or other news via internal social media to broader company audience and directors.",
        "Conducted a week of on-call rotation for our various services once every month. On call rotations did not bleed outside of current working hours.",
      ],
    },
    {
      companyName: "1Password",
      companyPageUrl: "https://1password.com",
      position: "Senior Web Developer | Core UI",
      stack: ["React", "Electron", "Typescript", "Rust"],
      term: "November 2021 - March 2022",
      responsibilities: [
        "Maintained user interface for desktop application. Implementing bug fixes, new features and implementing changes provided by design team.",
        "Was key stake holder in implementing the redesign of the Watchtower UI. Allowing users to see a summary of their 1Password vault(s) with a score representing how secure it is",
      ],
    },
    {
      companyName: "Ritual",
      companyPageUrl: "https://ritual.co",
      position: "Senior Web Engineer",
      stack: [
        "React",
        "Redux",
        "TypeScript",
        "OpenAPI/Swagger",
        "Component Driven Design",
      ],
      term: "January 2021 - August 2021",
      responsibilities: [
        "Refreshed tech stack emphasizing the decoupling of code, improving developer experience, and enabling the team to create more maintainable features.",
        "Actively supported the team throughout the transition of an updated tech stack. Creating documentation, providing mentorship to developers in group and individual settings, remaining transparent and collaborative while changes were being considered/made.",
        "Collaborated closely with the UX/Design team to conceptualize, plan, and build a highly extensible and accessible design system.",
      ],
    },
    {
      companyName: "Cover",
      companyPageUrl: "https://cover.com",
      position: "Software Developer (Web team)",
      stack: [
        "React",
        "React Storybook",
        "TypeScript",
        "CSS-in-JS",
        "Jest",
        "React Testing Library",
        "Lerna",
      ],
      term: "October 2019 - August 2020",
      responsibilities: [
        "Led frontend web team building advisor facing dashboard from ground up using clean architecture, Formik, and a custom state management solution using react context and a selector/reducer pattern similar to Redux",
        "Developed, architected, and maintained a JavaScript/Typescript monorepo managed using lerna, yarn workspaces, and custom tooling to handle issues related to caching, dependency management, reducing overhead for deploying/publishing new apps/libraries by providing plug-able lifecycle those projects could hook into.",
        "Build an in-house CI/CD pipeline and lifecycle that managed: versioning of our apps and libraries, automatic publishing to NPM for any library, automatic docker builds and push ",
        "Authored a React component library and design system built on top of theme-ui, styled-system, React hooks, and React Storybook.",
      ],
    },
    {
      companyName: "Windscribe",
      companyPageUrl: "https://windscribe.com",
      position: "JavaScript/Webextension Developer",
      stack: [
        "React",
        "Redux",
        "Webextension API",
        "Docker",
        "MongoDB",
        "GraphQL",
        "TypeScript",
        "Gitlab CI/CD",
      ],
      term: "June 2017 - October 2019",
      responsibilities: [
        "Designed and built a cross platform web extension focused on giving users tools to improve their internet privacy in the browser. The extension manages a web proxy, built-in adblocking, gps spoofing, and timezone spoofing.",
        'Built the frontend to "Garry": Windscribe\'s chat-bot/real-time support chat portal that lives on the website. The objective was to create a simple, small, and performant widget to add to the current website.  After releasing Garry, there was a 60% drop in open support tickets.',
        "Wrote a number of internal tools and tooling that the web team utilized. These tools included projects such as: a custom react-scripts module for web extension development using create-react-app, docker images to run e2e/integration tests in the extension using xvfb, and automating the deployment of our web extension for both Firefox and Chrome.",
        "Developed a in house full stack translation service prototype, in order to improve the experience working with outsourced translators.",
      ],
    },
    {
      companyName: "Juice Mobile",
      companyPageUrl: "https://www.juicemobile.com",
      position: "Junior Full Stack Developer",
      stack: [
        "JavaScript",
        "Angular JS",
        "Python",
        "Django",
        "Django Rest Framework",
        "Redis",
        "PostgreSQL",
      ],
      term: "September 2016 - June 2017",
      summary:
        "Worked on Swarm DSP. Contributing to both the Angular 1/es6 frontend, and the Django API.",
      responsibilities: [
        "Worked on major refactors of the frontend to provide a better developer experience and allow for faster, more robust features to be built.",
        "Migrated a large portion of data handling of the API, from saving into redis to using redis as a cache instead, then save into PostgreSQL.",
        "Took advantage of the team's overall experience, learning as much as possible from them and other senior engineers in the company.",
      ],
    },
  ],
};
