import * as React from "react";
import { Box, Flex, Text, useColorMode } from "theme-ui";
import { size, transitions } from "polished";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import Summary from "./Summary";
import Work from "./Work";
import cv from "./cv";

let DownloadCv = () => {
  let [mode] = useColorMode();
  if (navigator.userAgent === "ReactSnap") return <React.Fragment />;
  return (
    <Flex
      as="a"
      role="button"
      title="Download cv"
      href={`${window.location.origin}/downloads/cv.pdf`}
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        minWidth: "63px",
        appearance: "none",
        cursor: "pointer",
        padding: "10px",
        "@media print": { display: "none" },
        svg: { opacity: 0.5, transition: "opacity ease 0.3s" },
        "&:hover": {
          svg: { opacity: 1 },
        },
      }}
    >
      <DownloadIcon
        style={{ ...size(30), fill: mode === "dark" ? "#fff" : "#000" }}
        aria-hidden
      />
      <Text as="span" sx={{ fontSize: 0 }}>
        Download CV
      </Text>
    </Flex>
  );
};

let Links = () => {
  let get = (value, key) => (typeof value === "string" ? value : value[key]);
  return (
    <Box>
      <SectionTitle> Links and Contact info</SectionTitle>
      <Box sx={{ mt: 2 }}>
        {Object.entries(cv.contactInfo).map(([platform, link]) => (
          <Flex key={platform} sx={{ fontSize: 3 }}>
            <Text as="p" mr={1} sx={{ color: "black" }}>
              {platform}:{" "}
            </Text>
            <Text
              as="a"
              href={get(link, "href")}
              sx={{
                color: "link",
                fontWeight: "bold",
                "&:hover": { color: "linkHover" },
              }}
              rel="noopener"
              target="_blank"
            >
              {get(link, "title")}
            </Text>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export let SectionTitle = (props) => (
  <Text as="p" variant="sectionTitle" {...props} />
);

export let SkillItem = (skill) => (
  <Text as="p" key={skill} variant="skillItem">
    {skill}
  </Text>
);

export let SubText = (props) => <Text {...props} />;

let FullName = () => (
  <Text
    as="h1"
    sx={{
      my: 1,
      fontWeight: "bold",
      fontSize: 7,
    }}
  >
    {cv.fullName}
  </Text>
);

let ThemeToggle = () => {
  let [colorMode, setColorMode] = useColorMode();

  const iconStyle = {
    default: {
      backgroundColor: "black",
    },
    dark: {
      backgroundColor: "white",
    },
  };
  React.useEffect(() => {
    const enableBodyTransition = () =>
      document.body.classList.add("shouldTransition");

    setTimeout(enableBodyTransition, 1000);
  }, []);

  return (
    <Flex
      as="button"
      title="Toggle dark mode"
      className="theme-toggle-button"
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        appearance: "none",
        width: "70px",
        cursor: "pointer",
        padding: "10px",
        "&:hover": {
          background: "none",
          div: {
            opacity: 1,
          },
        },
        "@media print": {
          display: "none",
        },
        "&:focus": {
          div: {
            opacity: 1,
          },
          outline: "none",
        },
        "&:active": {
          div: {
            opacity: 1,
          },
          outline: "none",
        },
      }}
      onClick={() => setColorMode(colorMode === "default" ? "dark" : "default")}
    >
      <Box
        sx={{
          borderRadius: "50%",
          opacity: 0.6,
          ...size(30),
          ...iconStyle[colorMode],
          ...transitions(
            ["background-color", "opacity", "border-color"],
            "ease 0.3s"
          ),
        }}
      />
      <Text
        as="span"
        sx={{ color: colorMode === "default" ? "black" : "white", fontSize: 0 }}
      >
        Use {colorMode === "default" ? "Dark" : "Light"}
      </Text>
    </Flex>
  );
};

export default () => {
  return (
    <Box
      className="resume-wrapper"
      sx={{
        margin: "0 auto",
        padding: ["2rem 1.4rem", "2rem", "3rem", "4rem"],
        maxWidth: "1000px",
        "@media print": {
          padding: "1rem 2rem",
          maxWidth: "unset",
        },
      }}
    >
      <Flex
        as="header"
        sx={{
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Flex sx={{ width: "100%", flexWrap: "wrap" }}>
          <Box sx={{ flex: 3 }}>
            <FullName />
          </Box>
          <Flex ml="auto" sx={{ flexWrap: "wrap", flex: 1 }}>
            <Box mr={3}>
              <ThemeToggle />
            </Box>
            <DownloadCv />
          </Flex>
        </Flex>
        <Flex
          sx={{
            alignItems: "flex-start",
            width: "100%",
            flexWrap: "wrap",
            mt: 2,
          }}
        >
          <Box mr="auto">
            <Summary />
          </Box>
          <Links />
        </Flex>
      </Flex>

      <main>
        <Work />
      </main>
    </Box>
  );
};
